export const PAGES_MENU_ITEMS = [
  {
    slug: 'portfolio',
    name: 'Portfolio',
  },
  {
    slug: 'sobre-mi',
    name: 'Sobre Mi',
  },
  {
    slug: 'blog',
    name: 'Blog',
  },
];
